import React, { useState, useEffect } from 'react';
import { Statistic } from 'antd';

const Timer = ({ duration, onTimeUp }) => {
  const [time, setTime] = useState(duration);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(prev => {
        const newTime = prev - 1;
        if (newTime <= 0) {
          clearInterval(interval);
          onTimeUp();
        }
        return newTime;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [onTimeUp]);

  return (
    <div className="timer-container">
      <Statistic.Countdown value={Date.now() + time * 1000} onFinish={onTimeUp} />
    </div>
  );
};

export default Timer;
