// Quiz.js
import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'antd';
import '../styles/styles.css';
import { getDocs, collection, getFirestore, query, where, Timestamp } from 'firebase/firestore';
import Question from './Question';
import Results from './Results';
import Timer from './Timer';
import { useQuiz } from './QuizContext'; // Import useQuiz from your context

const Quiz = () => {
    const [quizKey, setQuizKey] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [isQuizComplete, setIsQuizComplete] = useState(false);
    const [loading, setLoading] = useState(true);
    const [quizStartTime, setQuizStartTime] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const { completeQuiz } = useQuiz(); // Get completeQuiz function from context

    const { topic } = useParams();

    useEffect(() => {
        setQuizKey(prevKey => prevKey + 1);
        setQuizStartTime(Timestamp.now());
    }, []);


    const shuffleArray = useCallback((array) => {
        let curId = array.length;
        while (0 !== curId) {
            const randId = Math.floor(Math.random() * curId);
            curId -= 1;
            const tmp = array[curId];
            array[curId] = array[randId];
            array[randId] = tmp;
        }
        return array;
    }, []); // Empty dependency array, since this function does not depend on any external state or props


    const fetchQuestions = useCallback(async () => {
        try {
            const db = getFirestore();
            let q;

            // Mapping of URL-friendly topic names to exact topic names in the Firestore
            const topicMapping = {
                'derivatives': 'Derivatives',
                'ethical-and-professional-standards': 'Ethical and Professional Standards',
                'economics': 'Economics',
                'fixed-income': 'Fixed Income',
                'equity-investments': 'Equity Investments',
                'quantitative-methods': 'Quantitative Methods',
                'corporate-issuers': 'Corporate Issuers',
                'portfolio-management-and-wealth-planning': 'Portfolio Management and Wealth Planning',
                'financial-reporting-and-analysis': 'Financial Reporting and Analysis',
                'alternative-investments': 'Alternative Investments',

                // Add more mappings as needed
            };

            // Look up the exact topic name based on the URL-friendly topic name
            const exactTopic = topicMapping[topic.toLowerCase()];

            if (exactTopic) {
                q = query(collection(db, 'questions'), where('topic', '==', exactTopic));
            } else if (topic === 'all') {
                q = collection(db, 'questions');
            } else {
                console.error('Topic not found:', topic);
                setLoading(false);
                return;
            }

            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const fetchedQuestions = querySnapshot.docs.map(doc => doc.data());
                setQuestions(shuffleArray(fetchedQuestions));
                setLoading(false);
            } else {
                console.error('No questions found for topic:', exactTopic);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching questions:', error);
            setLoading(false);
        }
    }, [topic, shuffleArray]); // Include both topic and shuffleArray in the dependency array

    useEffect(() => {
        fetchQuestions();
    }, [fetchQuestions]); // Now you include fetchQuestions in the dependency array

    const selectOption = (index) => {
        const newAnswers = [...selectedAnswers];
        newAnswers[currentQuestion] = index;
        setSelectedAnswers(newAnswers);
    };

    const resetAndFetchQuestions = async () => {
        setLoading(true);
        await fetchQuestions();
        setCurrentQuestion(0);
        setSelectedAnswers([]);
        setIsQuizComplete(false);
        setLoading(false);
        setSubmitted(false); // Reset the submitted flag when quiz restarts
    };

    const goToNextQuestion = () => {
        if (currentQuestion < questions.length - 1) {
            setCurrentQuestion(currentQuestion + 1);
        } else {
            setIsQuizComplete(true);
        }
    };

    const goToPreviousQuestion = () => {
        if (currentQuestion > 0) {
            setCurrentQuestion(currentQuestion - 1);
        }
    };

    const onTimeUp = () => {
        setIsQuizComplete(true);
    };

    const endQuiz = () => {
        setIsQuizComplete(true);
        const quizResults = {
            questions,
            selectedAnswers,
            // You should define calculateScore based on your logic
            // ...any other data you want to pass
        };
        completeQuiz(quizResults); // Call completeQuiz from context with results
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (isQuizComplete) {
        return <Results 
                  key={quizKey} 
                  setQuizKey={setQuizKey} 
                  questions={questions} 
                  selectedAnswers={selectedAnswers} 
                  resetAndFetchQuestions={resetAndFetchQuestions} 
                  isQuizComplete={isQuizComplete}
                  quizStartTime={quizStartTime}
                  submitted={submitted}
                  setSubmitted={setSubmitted} // Pass setSubmitted to Results
                  topic={topic} // Pass the topic here
               />;
    }

    return (
        <div className="quiz-container">
            <div className="timer-container">
                <Timer duration={60 * 135} onTimeUp={onTimeUp} />
            </div>
            <div className="question-count">
                Question {currentQuestion + 1} of {questions.length}
            </div>
            <Question
                question={questions[currentQuestion]}
                selectOption={selectOption}
                selectedOption={selectedAnswers[currentQuestion]}
            />
            <div className="navigation-buttons">
                <Button disabled={currentQuestion === 0} onClick={goToPreviousQuestion}>Previous</Button>
                <Button type="primary" onClick={goToNextQuestion}>
                    {currentQuestion === questions.length - 1 ? 'Finish' : 'Next'}
                </Button>
                <Button type="danger" onClick={endQuiz} style={{ position: 'absolute', right: 0, bottom: 0 }}>
                    End Quiz
                </Button>
            </div>
        </div>
    );
};

export default Quiz;