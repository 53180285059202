import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { Form, Input, Button, Collapse, Modal, notification, Select, Checkbox } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { format, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';

const { Option } = Select;
const { Panel } = Collapse;

const EditQuestionsPage = () => {
    const [questions, setQuestions] = useState([]);
    const [editingQuestion, setEditingQuestion] = useState(null);
    const [topicsMapping, setTopicsMapping] = useState({});
    const [sortAscending, setSortAscending] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");

    const sortQuestionsByDate = () => {
        const sortedQuestions = [...questions].sort((a, b) => {
            const dateA = new Date(a.submittedTime);
            const dateB = new Date(b.submittedTime);
            return sortAscending ? dateA - dateB : dateB - dateA;
        });
        setQuestions(sortedQuestions);
        setSortAscending(!sortAscending);
    };

    useEffect(() => {
        const fetchQuestions = async () => {
            const db = getFirestore();
            const querySnapshot = await getDocs(collection(db, "questions"));
            const fetchedQuestions = querySnapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    id: doc.id,
                    ...data,
                    submittedTimeFormatted: data.submittedTime ? format(parseISO(data.submittedTime), 'PPpp') : 'Unknown',
                    lastUpdatedFormatted: data.lastUpdated ? format(parseISO(data.lastUpdated), 'PPpp') : 'Never'
                };
            });
            setQuestions(fetchedQuestions);

            // Group questions by topic
            const groupedByTopic = fetchedQuestions.reduce((acc, question) => {
                acc[question.topic] = acc[question.topic] || [];
                acc[question.topic].push({
                    ...question,
                    // Parse the string to a Date object before formatting
                    submittedTimeFormatted: question.submittedTime ? format(parseISO(question.submittedTime), 'PPpp') : 'Unknown'
                });
                return acc;
            }, {});
            setTopicsMapping(groupedByTopic);
        };

        fetchQuestions();
    }, []);

    useEffect(() => {
        // Apply search filter to questions before grouping them by topic
        const filteredQuestions = questions.filter(question =>
            question.question.toLowerCase().includes(searchTerm)
        );

        // Group the filtered questions by topic
        const groupedByTopic = filteredQuestions.reduce((acc, question) => {
            const topicKey = question.topic || 'Other';
            acc[topicKey] = acc[topicKey] || [];
            acc[topicKey].push(question);
            return acc;
        }, {});

        setTopicsMapping(groupedByTopic);
    }, [questions, searchTerm]); // Recalculate topicsMapping when questions or searchTerm changes


    // Save edited question to Firestore
    const saveQuestion = async (values) => {
        try {
            const db = getFirestore();
            const questionRef = doc(db, "questions", editingQuestion.id);
            const nowISO = new Date().toISOString();

            await updateDoc(questionRef, { ...values, lastUpdated: nowISO });

            // Update the questions state
            const updatedQuestions = questions.map(q => {
                if (q.id === editingQuestion.id) {
                    return {
                        ...q,
                        ...values, // Updated values from the form
                        lastUpdated: nowISO, // Update lastUpdated time
                        lastUpdatedFormatted: format(parseISO(nowISO), 'PPpp'), // Format lastUpdated time for display
                        submittedTimeFormatted: q.submittedTime ? format(parseISO(q.submittedTime), 'PPpp') : 'Unknown'
                    };
                }
                return q;
            });

            setQuestions(updatedQuestions);

            // Recalculate topicsMapping with updated questions
            const updatedTopicsMapping = updatedQuestions.reduce((acc, question) => {
                const topic = question.topic || 'Other';
                acc[topic] = acc[topic] || [];
                acc[topic].push(question);
                return acc;
            }, {});

            setTopicsMapping(updatedTopicsMapping);

            // Close the modal after saving
            setEditingQuestion(null);

            notification.success({
                message: 'Question Updated',
                description: 'The question has been updated successfully.'
            });

        } catch (error) {
            console.error("Error updating document: ", error);
            notification.error({
                message: 'Update Failed',
                description: 'There was an error updating the question.'
            });
        }
    };

    const deleteQuestion = async (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this question?',
            content: 'This action cannot be undone.',
            okText: 'Yes, delete it',
            okType: 'danger',
            cancelText: 'No, cancel',
            onOk: async () => {
                try {
                    const db = getFirestore();
                    const questionId = typeof id === 'number' ? id.toString() : id; // Convert numeric IDs to string
                    await deleteDoc(doc(db, "questions", questionId));

                    // After successful deletion, update local state
                    const updatedQuestions = questions.filter(question => question.id !== questionId);
                    setQuestions(updatedQuestions);

                    // Update topicsMapping state
                    const updatedTopicsMapping = { ...topicsMapping };
                    Object.keys(updatedTopicsMapping).forEach(topic => {
                        updatedTopicsMapping[topic] = updatedTopicsMapping[topic].filter(question => question.id !== questionId);
                    });
                    setTopicsMapping(updatedTopicsMapping);

                    notification.success({
                        message: 'Question Deleted',
                        description: 'The question has been deleted successfully.'
                    });
                } catch (error) {
                    console.error("Error deleting document: ", error);
                    notification.error({
                        message: 'Deletion Failed',
                        description: 'There was an error deleting the question.'
                    });
                }
            },
        });
    };

    const NewlineText = ({ text }) => {
        return text.split('\n').map((str, index, array) => (
            <React.Fragment key={index}>
                {str}
                {index !== array.length - 1 && <br />}
            </React.Fragment>
        ));
    };

    const onSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    return (
        <div style={{ padding: '20px', marginTop: '90px' }}>
            <h2>Total Questions: {questions.length}</h2>
            <div style={{ marginBottom: '20px', textAlign: 'center' }}>
                <Input
                    placeholder="Search Questions"
                    onChange={onSearchChange}
                    style={{ width: 200, marginRight: '10px' }}
                />
                <Button onClick={sortQuestionsByDate}>
                    Sort by Date Added {sortAscending ? <DownOutlined /> : <UpOutlined />}
                </Button>
            </div>
            <Collapse>
                {Object.entries(topicsMapping).map(([topic, topicQuestions]) => (
                    <Panel header={`${topic} (${topicQuestions.length})`} key={topic}>
                        {topicQuestions.map(item => (
                            <div key={item.id} style={{ background: '#f7f7f7', marginBottom: '10px', borderRadius: '4px', padding: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div>
                                    <b><NewlineText text={item.question} /></b>
                                    <p>Date Added: {item.submittedTimeFormatted}</p>
                                    <p>Last Updated: {item.lastUpdatedFormatted}</p>
                                    {/* Link to analytics page */}
                                    <Link to={`/analytics/${item.id}`}>View Analytics</Link>
                                </div>
                                <div className="button-container" style={{ display: 'flex', alignItems: 'center' }}>
                                    <Button type="primary" onClick={() => setEditingQuestion(item)} style={{ marginRight: '10px' }}>Edit</Button>
                                    <Button type="danger" onClick={() => deleteQuestion(item.id)} style={{ marginRight: '10px' }}>Delete</Button>
                                    <Checkbox /> {/* Checkbox */}
                                </div>
                            </div>
                        ))}
                    </Panel>
                ))}
            </Collapse>
            <Modal
                title="Edit Question"
                open={!!editingQuestion}
                onCancel={() => setEditingQuestion(null)}
                footer={null}
                style={{ top: '20px' }}
            >
                {editingQuestion && (
                    <Form
                        key={editingQuestion.id}
                        initialValues={{
                            topic: editingQuestion.topic,
                            question: editingQuestion.question,
                            options: editingQuestion.options,
                            correctAnswer: editingQuestion.correctAnswer,
                            explanation: editingQuestion.explanation
                        }}
                        onFinish={saveQuestion}
                        layout="vertical"
                    >
                        <Form.Item
                            name="topic"
                            label="Topic"
                            rules={[{ required: true, message: 'Please input the topic!' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="question"
                            label="Question"
                            rules={[{ required: true, message: 'Please input the question!' }]}
                        >
                            <Input.TextArea />
                        </Form.Item>
                        {['Option A', 'Option B', 'Option C'].map((option, index) => (
                            <Form.Item
                                key={index}
                                name={['options', index]}
                                label={option}
                                rules={[{ required: true, message: `Please input ${option}!` }]}
                            >
                                <Input />
                            </Form.Item>
                        ))}
                        <Form.Item
                            name="correctAnswer"
                            label="Correct Answer"
                            rules={[{ required: true, message: 'Please select the correct answer!' }]}
                        >
                            <Select>
                                <Option value="0">Option A</Option>
                                <Option value="1">Option B</Option>
                                <Option value="2">Option C</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="explanation"
                            label="Explanation"
                            rules={[{ required: true, message: 'Please input the explanation!' }]}
                        >
                            <Input.TextArea />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" block>
                                Save
                            </Button>
                        </Form.Item>
                    </Form>
                )}
            </Modal>
        </div>
    );
};



export default EditQuestionsPage;