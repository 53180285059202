// QuizContext.js
import React, { createContext, useContext, useState } from 'react';

const QuizContext = createContext();

export const useQuiz = () => useContext(QuizContext);

export const QuizProvider = ({ children }) => {
    const [quizData, setQuizData] = useState({
        isComplete: false,
        results: null,
        // ...other quiz states
    });

    const completeQuiz = (results) => {
        setQuizData({ ...quizData, isComplete: true, results: results });
    };

    return (
        <QuizContext.Provider value={{ quizData, completeQuiz }}>
            {children}
        </QuizContext.Provider>
    );
};
