import React, { useState, useEffect, useCallback } from 'react';
import { Table, Button, Modal, Typography, Card, Select } from 'antd';
import { collection, query, where, getDocs, deleteDoc, doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';

const { Title } = Typography;

const NewlineText = ({ text }) => {
    const newText = text.split('\n').map((str, index, array) =>
        index === array.length - 1 ? str : <>
            {str}
            <br />
        </>
    );
    return <>{newText}</>;
};

const QuizAttempts = () => {
    const [attempts, setAttempts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedAttempt, setSelectedAttempt] = useState(null); // State for the selected attempt
    const [users, setUsers] = useState([]); // State for storing user profiles
    const [filterUserId, setFilterUserId] = useState(null); // State for filtering by user ID
    const [isAdmin, setIsAdmin] = useState(false); // Add state for isAdmin

    const fetchUserProfiles = useCallback(async () => {
        try {
            const querySnapshot = await getDocs(collection(db, "userProfiles"));
            const usersData = querySnapshot.docs.map(doc => ({ ...doc.data(), uid: doc.id }));
            setUsers(usersData);
        } catch (error) {
            console.error("Error fetching user profiles:", error);
        }
    }, []);

    const fetchQuizAttempts = useCallback(async (isAdminParam) => {
        try {
            let q;
            if (isAdminParam) {
                q = collection(db, "quizAttempts");
            } else {
                q = query(collection(db, "quizAttempts"), where("uid", "==", auth.currentUser.uid));
            }
            const querySnapshot = await getDocs(q);
            const attemptsData = querySnapshot.docs.map(doc => {
                const attempt = { ...doc.data(), key: doc.id };
                const userProfile = users.find(user => user.uid === attempt.uid);
                if (userProfile) {
                    attempt.email = userProfile.email;
                    attempt.name = userProfile.name;
                }
                return attempt;
            }).sort((a, b) => b.endTime.seconds - a.endTime.seconds);
            setAttempts(attemptsData);
        } catch (error) {
            console.error("Error fetching quiz attempts:", error);
        }
    }, [users]);

    useEffect(() => {
        let unsubscribe;
        (async () => {
            unsubscribe = onAuthStateChanged(auth, async (user) => {
                if (user) {
                    const userDoc = await getDoc(doc(db, "userProfiles", user.uid));
                    const isAdminUser = userDoc.exists() && userDoc.data().isAdmin;
                    setIsAdmin(isAdminUser);
                    if (isAdminUser && users.length === 0) { // Fetch user profiles only if needed
                        await fetchUserProfiles();
                    }
                    await fetchQuizAttempts(isAdminUser);
                } else {
                    setLoading(false);
                }
            });
        })();

        return () => unsubscribe && unsubscribe();
    }, [fetchUserProfiles, fetchQuizAttempts, users.length]); // Add users.length as dependency

    useEffect(() => {
        if (isAdmin !== null) {
            fetchQuizAttempts(isAdmin);
        }
    }, [isAdmin, fetchQuizAttempts]);

     useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const userDoc = await getDoc(doc(db, "userProfiles", user.uid));
                if (userDoc.exists() && userDoc.data().isAdmin) {
                    setIsAdmin(true);
                    fetchUserProfiles(); // Now this function is accessible here
                }
                fetchQuizAttempts(isAdmin); // Pass isAdmin to fetchQuizAttempts
            }
        });

        return () => unsubscribe();
        // eslint-disable-next-line
    }, [isAdmin,]); // Include fetchQuizAttempts in the dependency array

    const deleteAttempt = async (id) => {
        try {
            await deleteDoc(doc(db, "quizAttempts", id));
            fetchQuizAttempts(); // Re-fetch the attempts after deletion
        } catch (error) {
            console.error("Error deleting quiz attempt:", error);
        }
    };

    const viewAttempt = (attempt) => {
        setSelectedAttempt(attempt); // Set the attempt to view
    };

    const columns = [
        {
            title: 'Date Started',
            dataIndex: 'startTime', // Assuming startTime is a Timestamp
            key: 'startTime',
            render: (text) => text.toDate().toLocaleString(),
        },
        {
            title: 'Date Ended',
            dataIndex: 'endTime', // Assuming endTime is a Timestamp
            key: 'endTime',
            render: (text) => text.toDate().toLocaleString(),
        },
        {
            title: 'UID',
            dataIndex: 'uid',
            key: 'uid',
            render: text => <span>{text}</span>,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: text => <span>{text}</span>,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: text => <span>{text}</span>,
        },
        {
            title: 'Topic',
            dataIndex: 'topic',
            key: 'topic',
        },
        {
            title: 'Score',
            dataIndex: 'score',
            key: 'score',
        },
        {
            title: 'Questions',
            dataIndex: 'questions',
            key: 'questions',
            render: questions => questions.length,
        },
        {
            title: 'Correct Answers',
            key: 'correctAnswers',
            render: (record) => {
                // Assuming 'record.questions' contains the list of questions and 'record.answers' contains user's answers
                let correctCount = 0;
                record.questions.forEach((question, index) => {
                    if (record.answers[index] === question.correctAnswer) {
                        correctCount += 1;
                    }
                });
                return correctCount;
            },
        },
        {
            title: 'ID',
            dataIndex: 'key',
            key: 'id',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <>
                    <Button type="link" onClick={() => viewAttempt(record)}>
                        View Quiz
                    </Button>
                    {isAdmin && (
                        <Button type="link" onClick={() => deleteAttempt(record.key)}>
                            Delete
                        </Button>
                    )}
                </>
            ),
        },
    ];

    return (
        <div>
            <h1>Your Quiz Attempts</h1>
            {isAdmin && (
                <Select
                    style={{ width: 200 }}
                    placeholder="Filter by user"
                    onChange={value => setFilterUserId(value)}
                    allowClear
                >
                    {users.map(user => (
                        <Select.Option key={user.uid} value={user.uid}>{user.name}</Select.Option>
                    ))}
                </Select>
            )}
            <Table dataSource={attempts.filter(attempt => !filterUserId || attempt.uid === filterUserId)} columns={columns} loading={loading} />

            {/* Modal to show attempt details */}
            {selectedAttempt && (
                <Modal
                    title={`Quiz Attempt Details - ${selectedAttempt.endTime.toDate().toLocaleString()} - ID: ${selectedAttempt.key}`}
                    visible={!!selectedAttempt}
                    onCancel={() => setSelectedAttempt(null)}
                    footer={null}
                    width="80%"
                >
                    <Title level={4}>Score: {selectedAttempt.score}%</Title>
                    <div className="questions-results">
                        {selectedAttempt.questions.map((question, index) => (
                            <Card key={index} style={{ margin: '10px 0' }}>
                                <h3>Q{index + 1}: <NewlineText text={question.question} /></h3>
                                {question.options.map((option, optionIndex) => {
                                    const isCorrect = optionIndex.toString() === question.correctAnswer;
                                    const isSelected = selectedAttempt.answers[index] === optionIndex.toString();
                                    return (
                                        <p key={optionIndex} style={{
                                            color: isCorrect ? 'green' : (isSelected ? 'red' : 'black'),
                                            fontWeight: isCorrect || isSelected ? 'bold' : 'normal'
                                        }}>
                                            {String.fromCharCode(65 + optionIndex)}: {option}
                                        </p>
                                    );
                                })}
                                {question.explanation && (
                                    <p style={{ fontStyle: 'italic' }}>
                                        Explanation: <NewlineText text={question.explanation} />
                                    </p>
                                )}
                            </Card>
                        ))}
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default QuizAttempts;