import React, { useEffect } from 'react';
import { updateProfile } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore"; // Import setDoc for creating or updating documents
import { auth, db } from '../firebase';
import { Form, Input, Button, Typography, notification } from 'antd'; // Import notification

const { Title } = Typography;

const EditProfile = () => {

    useEffect(() => {
        if (auth.currentUser) {
            form.setFieldsValue({
                email: auth.currentUser.email,
                name: auth.currentUser.displayName || '',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [form] = Form.useForm();

    const handleSubmit = async (values) => {
        try {
            const { name } = values;

            if (name) {
                await updateProfile(auth.currentUser, {
                    displayName: name
                });

                const userRef = doc(db, "userProfiles", auth.currentUser.uid);
                await setDoc(userRef, {
                    name: name
                }, { merge: true }); // Use setDoc with merge to create or update the document

                notification.success({ // Success notification
                    message: 'Profile Updated',
                    description: 'Your profile has been updated successfully.',
                });
            }
        } catch (error) {
            console.error('Error updating profile:', error);
            notification.error({ // Error notification
                message: 'Profile Update Failed',
                description: error.message,
            });
        }
    };

    return (
        <div style={{ maxWidth: '400px', margin: 'auto', padding: '20px' }}>
            <Title level={2}>Edit Profile</Title>
            <Form form={form} onFinish={handleSubmit}>
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: 'Please input your name!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                >
                    <Input disabled />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Update Profile
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default EditProfile;
