import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Quiz from './components/Quiz';
import Navbar from './components/Navbar';
import SignUp from './components/SignUp';
import SubmitQuestion from './components/SubmitQuestion';
import EditQuestion from './components/EditQuestion';
import LoginPage from './components/LoginPage';
import Glossary from './components/Glossary';
import StudyGuide from './components/StudyGuide';  // Import StudyGuide component here
import NotFoundPage from './components/NotFoundPage';
import EditQuestionsPage from './components/EditQuestionsPage';
import QuestionAnalytics from './components/QuestionAnalytics';
import EditProfile from './components/EditProfile'; // Import the EditProfile component
import QuizAttempts from './components/quizAttempts';
import { QuizProvider } from './components/QuizContext';


function App() {
  return (
    <QuizProvider>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/quiz" element={<Quiz />} />
          <Route path="/quiz/:topic" element={<Quiz />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/submit-question" element={<SubmitQuestion />} />
          <Route path="/edit-question" element={<EditQuestion />} />
          <Route path="/edit-questions" element={<EditQuestionsPage />} />
          <Route path="/glossary" element={<Glossary />} />
          <Route path="/study-guide/*" element={<StudyGuide />} />
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/quiz-attempts" element={<QuizAttempts />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/analytics/:questionId" element={<QuestionAnalytics />} />
        </Routes>
      </Router>
    </QuizProvider>
  );
}

export default App;
