import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { Card, Statistic, Row, Col } from 'antd';


const NewlineText = ({ text }) => {
    return text.split('\n').map((str, index, array) =>
        index === array.length - 1 ? str : <React.Fragment key={index}>{str}<br /></React.Fragment>
    );
};

const QuestionAnalytics = () => {
    const { questionId } = useParams();
    const [questionData, setQuestionData] = useState(null);

    useEffect(() => {
        const fetchQuestionData = async () => {
            const db = getFirestore();
            const questionRef = doc(db, "questions", questionId);
            const docSnap = await getDoc(questionRef);

            if (docSnap.exists()) {
                setQuestionData(docSnap.data());
            } else {
                console.log("No such question!");
            }
        };

        fetchQuestionData();
    }, [questionId]);

    return (
        <div className="analytics-container" style={{ textAlign: 'center' }}>
            {questionData ? (
                <Card style={{ margin: '20px auto', maxWidth: '800px', textAlign: 'left' }}>
                    <h3><NewlineText text={questionData.question} /></h3>
                    {questionData.options.map((option, index) => (
                        <p key={index}>{String.fromCharCode(65 + index)}: {option}</p>
                    ))}
                    <Row gutter={16} style={{ marginTop: '20px' }}>
                        <Col span={12}>
                            <Statistic title="Views" value={questionData.views} />
                        </Col>
                        <Col span={12}>
                            <Statistic title="Correct Answers" value={questionData.correctCount} />
                        </Col>
                        <Col span={12}>
                            <Statistic title="Incorrect Answers" value={questionData.incorrectCount} />
                        </Col>
                        <Col span={12}>
                            <Statistic 
                                title="Average Response Time" 
                                value={questionData.responseCount > 0 ? (questionData.totalResponseTime / questionData.responseCount).toFixed(2) : 'N/A'} 
                                suffix="seconds" 
                            />
                        </Col>
                    </Row>
                </Card>
            ) : (
                <p>Loading question data...</p>
            )}
        </div>
    );
};

export default QuestionAnalytics;
