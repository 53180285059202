import React, { useState } from 'react';
import { auth } from '../firebase';  
import { signInWithEmailAndPassword } from "firebase/auth";  
import { Card, Input, Button, notification } from 'antd'; 
import { WarningOutlined } from '@ant-design/icons';  
import { useNavigate } from 'react-router-dom'; 

const LoginPage = () => {
    const [formData, setFormData] = useState({ email: '', password: '' });
    const navigate = useNavigate();  

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, formData.email, formData.password);  
            navigate('/');  
        } catch (error) {
            console.error('Error during log in', error);
            notification.open({
                message: 'Login Error',
                description: error.message,
                icon: <WarningOutlined style={{ color: 'red' }} />,
            });
        }
    }

    return (
        <div className="login-container">
            <Card title="Log In" style={{ width: 300, margin: 'auto' }}>  
                <form onSubmit={handleSubmit}>
                    <label>
                        Email
                        <Input 
                            type="email" 
                            name="email" 
                            value={formData.email} 
                            onChange={handleChange} 
                            required 
                        />
                    </label>
                    <br/>
                    <label>
                        Password
                        <Input 
                            type="password" 
                            name="password" 
                            value={formData.password} 
                            onChange={handleChange} 
                            required 
                        />
                    </label>
                    <br/>
                    <Button type="primary" htmlType="submit" style={{ marginTop: '20px' }}>Log In</Button>
                </form>
            </Card>
        </div>
    );
}

export default LoginPage;
