import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();
  const topics = [
    'Ethical and Professional Standards',
    'Economics',
    'Quantitative Methods',
    'Financial Reporting and Analysis',
    'Corporate Issuers',
    'Portfolio Management and Wealth Planning',
    'Equity Investments',
    'Fixed Income',
    'Derivatives',
    'Alternative Investments',
  ];

  useEffect(() => {
    document.title = 'CFA Level 1 Mock Exam | Practice Tests for CFA Exam Preparation';
  }, []);

  return (
    <div style={{ marginTop: '40px' ,padding: '30px' }}>
      <Row gutter={16} justify="center">
        {topics.map((topic, index) => (
          <Col key={index} xs={24} sm={24} md={12} lg={8} xl={8} style={{ marginBottom: '20px' }}>
            <Card
              hoverable
              style={{ width: '100%' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>{topic}</span>
                <Link to={`/quiz/${topic.replace(/\s+/g, '-').toLowerCase()}`}>
                  <Button type="primary">
                    Start
                  </Button>
                </Link>
              </div>
            </Card>
          </Col>
        ))}
        <Col xs={24} sm={24} md={12} lg={8} xl={8} style={{ marginBottom: '20px' }}>
          <Card
            hoverable
            style={{ width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span><strong>All Topics</strong></span>
              <Link to="/quiz/all">
                <Button type="primary">
                  Start
                </Button>
              </Link>
            </div>
          </Card>
        </Col>
      </Row>
      <Col xs={24} sm={24} md={12} lg={8} xl={8} style={{ marginBottom: '20px' }}>
        <Card
          hoverable
          style={{ width: '100%' }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>CFA Level 1 Study Guide</span>
            <Button type="primary" onClick={() => navigate('/study-guide')}>
              Explore
            </Button>
          </div>
        </Card>
        <Card
          hoverable
          style={{ width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>Glossary</span>
            <Link to="/glossary">
              <Button type="primary">
                Explore
              </Button>
            </Link>
          </div>
        </Card>
      </Col>
    </div>
  );
};

export default Home;
