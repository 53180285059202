// SideMenu.js
import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import menuData from '../data/menuItems.json';  // Adjust path as needed

const { SubMenu } = Menu;

const renderMenuItem = item => (
    <Menu.Item key={item.key}>
        <Link to={`/study-guide/${item.key}`}>{item.title}</Link>  
    </Menu.Item>
);

const renderMenu = items => {
    if (!Array.isArray(items)) {
        console.error('items is not an array', items);
        return null;
    }

    return items.map(item => {
        if (item.children && Array.isArray(item.children)) {
            return (
                <SubMenu key={item.key} title={item.title}>
                    {renderMenu(item.children)}
                </SubMenu>
            );
        }
        return renderMenuItem(item);
    });
};

const sideMenu = (
    <Menu
        mode="inline"
        defaultSelectedKeys={['1']}
        style={{ height: '100%', borderRight: 0 }}
    >
        {renderMenu(menuData.menuItems)}
    </Menu>
);

export default sideMenu;
