import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { Form, Input, Select, Button, Card, notification, Modal, Collapse } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;
const { TextArea } = Input;
const { Option } = Select;
const { Panel } = Collapse;

const EditQuestion = () => {
    const [questions, setQuestions] = useState([]);
    const [duplicates, setDuplicates] = useState({});
    const [modifiedIndexes, setModifiedIndexes] = useState({});
    const [edits, setEdits] = useState({});

    const db = getFirestore();

    const fetchQuestions = async () => {
        const db = getFirestore();
        const querySnapshot = await getDocs(collection(db, "questions"));

        let questionMap = new Map();

        querySnapshot.docs.forEach(doc => {
            const data = doc.data();
            const questionText = data.question;

            if (questionMap.has(questionText)) {
                questionMap.get(questionText).count += 1;
            } else {
                questionMap.set(questionText, {
                    data: {
                        id: doc.id,
                        ...data,
                        correctAnswer: data.correctAnswer
                    },
                    count: 1
                });
            }
        });

        const uniqueQuestions = Array.from(questionMap.values()).map(q => q.data);
        const duplicateCounts = Array.from(questionMap.values()).reduce((acc, q) => {
            acc[q.data.id] = q.count;
            return acc;
        }, {});

        setQuestions(uniqueQuestions);
        setDuplicates(duplicateCounts);
    };

    useEffect(() => {
        fetchQuestions();
    }, []);



    const updateQuestion = async (id) => {
        try {
            // First find the index of the question with the matching id
            const index = questions.findIndex(q => q.id === id);
            if (index === -1) {
                throw new Error('No question found with the given ID');
            }

            const question = questions[index];
            const editsForQuestion = edits[question.id];

            // Validate correctAnswer input
            if (!["0", "1", "2"].includes(editsForQuestion.correctAnswer)) {
                notification.error({
                    message: 'Error',
                    description: 'The correct answer must be A, B, or C'
                });
                return;
            }

            console.log('Question ID:', question.id);

            const questionRef = doc(db, "questions", question.id);
            await updateDoc(questionRef, editsForQuestion);

            notification.success({
                message: 'Success',
                description: 'Question updated successfully!'
            });

            // Update local state
            const newModifiedIndexes = { ...modifiedIndexes };
            delete newModifiedIndexes[index];
            setModifiedIndexes(newModifiedIndexes);

            setQuestions(prev => {
                const updatedQuestions = [...prev];
                // Update the question with the editsForQuestion
                updatedQuestions[index] = { ...updatedQuestions[index], ...editsForQuestion };
                return updatedQuestions;
            });

            // Remove the edits from the state as they are no longer pending
            const newEdits = { ...edits };
            delete newEdits[question.id];
            setEdits(newEdits);
        } catch (error) {
            notification.error({
                message: 'Error',
                description: `There was an error updating the question: ${error.message}`
            });
            console.error("Error updating document: ", error);
        }
    };


    const handleCorrectAnswerChange = (value, questionData) => {
        // Update the edits state, not the questions state
        setEdits(prevEdits => {
            const newEdits = { ...prevEdits };
            const questionEdits = newEdits[questionData.id] || { ...questionData };
            questionEdits.correctAnswer = value; // Update correct answer
            newEdits[questionData.id] = questionEdits; // Set the edits for this question
            return newEdits;
        });

        // Mark the question as modified
        const questionIndex = questions.findIndex(q => q.id === questionData.id);
        setModifiedIndexes(prevIndexes => ({ ...prevIndexes, [questionIndex]: true }));
    };

    const deleteQuestion = async (id) => {
        try {
            const questionRef = doc(db, "questions", id);
            await deleteDoc(questionRef);
            setQuestions(questions.filter(q => q.id !== id));

            notification.success({
                message: 'Success',
                description: 'Question deleted successfully!'
            });
        } catch (error) {
            notification.error({
                message: 'Error',
                description: `There was an error deleting the question: ${error.message}`
            });
            console.error("Error deleting document: ", error);
        }
    };

    const deleteDuplicates = async () => {
        const db = getFirestore();

        try {
            const duplicateQuestions = await getDocs(collection(db, "questions"));
            const questionMap = new Map();

            duplicateQuestions.docs.forEach(doc => {
                const data = doc.data();
                const questionText = data.question;

                if (questionMap.has(questionText)) {
                    questionMap.get(questionText).push(doc.id);
                } else {
                    questionMap.set(questionText, [doc.id]);
                }
            });

            const promises = [];
            questionMap.forEach(ids => {
                ids.pop(); // keep one copy
                ids.forEach(id => promises.push(deleteDoc(doc(db, "questions", id))));
            });

            await Promise.all(promises);

            notification.success({
                message: 'Success',
                description: 'All duplicate questions have been deleted!',
            });

            // Refresh the question list
            fetchQuestions();

        } catch (error) {
            notification.error({
                message: 'Error',
                description: `There was an error deleting duplicate questions: ${error.message}`
            });
            console.error("Error deleting duplicates: ", error);
        }
    };

    const showDeleteConfirm = () => {
        confirm({
            title: 'Are you sure you want to delete all duplicates?',
            icon: <ExclamationCircleOutlined />,
            content: 'This action cannot be undone',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteDuplicates();
            },
        });
    };

    const handleChange = (e, questionId, optionIndex = null) => {
        const { name, value } = e.target;
    
        setEdits(prevEdits => {
            const newEdits = { ...prevEdits };
            const questionEdits = newEdits[questionId] || { ...questions.find(q => q.id === questionId) };
    
            if (optionIndex !== null) {
                // Update options array
                questionEdits.options = [...questionEdits.options];
                questionEdits.options[optionIndex] = value;
            } else {
                // Update other fields
                questionEdits[name] = value;
            }
    
            newEdits[questionId] = questionEdits;
            return newEdits;
        });
    
        // Mark the question as modified
        const questionIndex = questions.findIndex(q => q.id === questionId);
        setModifiedIndexes(prevIndexes => ({ ...prevIndexes, [questionIndex]: true }));
    };


    const topicsMapping = questions.reduce((acc, question) => {
        const topic = question.topic;
        if (!acc[topic]) {
            acc[topic] = {
                questions: [],
                count: 0
            };
        }
        acc[topic].questions.push(question);
        acc[topic].count += 1;
        return acc;
    }, {});


    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '60px' }}>
            {/* Iterate through each topic and render its questions */}
            <Collapse defaultActiveKey={Object.keys(topicsMapping)}>
                {Object.entries(topicsMapping).map(([topic, { questions: topicQuestions, count }]) => (
                    <Panel header={`${topic} (${count} questions)`} key={topic}>
                        {topicQuestions.map((questionData, index) => (
                            <Card
                                title={`Edit Question${duplicates[questionData.id] > 1 ? ` - Duplicates: ${duplicates[questionData.id] - 1}` : ''}`}
                                style={{ width: 800, marginBottom: '20px' }}
                                key={questionData.id}
                            >
                                <Form>
                                    <Form.Item label="Topic">
                                        <Input
                                            name="topic"
                                            value={questionData.topic}
                                            onChange={(e) => handleChange(e, index)}
                                            required
                                        />
                                    </Form.Item>
                                    <Form.Item label="Question">
                                        <TextArea
                                            name="question"
                                            value={questionData.question}
                                            onChange={(e) => handleChange(e, index)}
                                            required
                                        />
                                    </Form.Item>
                                    {questionData.options.map((option, i) => (
                                        <Form.Item label={`Option ${String.fromCharCode(65 + i)}`} key={i}>
                                            <Input
                                                value={option}
                                                onChange={(e) => {
                                                    const value = e.target.value;

                                                    // Update the options in an immutable way
                                                    setQuestions(prevQuestions => {
                                                        const newQuestions = [...prevQuestions];
                                                        newQuestions[index] = {
                                                            ...newQuestions[index],
                                                            options: newQuestions[index].options.map((opt, optIndex) =>
                                                                optIndex === i ? value : opt
                                                            )
                                                        };
                                                        return newQuestions;
                                                    });

                                                    // Mark the question as modified
                                                    setModifiedIndexes(prevIndexes => ({ ...prevIndexes, [index]: true }));
                                                }}
                                                required
                                            />
                                        </Form.Item>
                                    ))}
                                    <Form.Item label="Correct Answer">
                                        <Select
                                            value={questionData.correctAnswer ? questionData.correctAnswer.toString() : ""}
                                            onChange={(value) => handleCorrectAnswerChange(value, questionData)}
                                        >
                                            <Option value="0">Option A</Option>
                                            <Option value="1">Option B</Option>
                                            <Option value="2">Option C</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label="Explanation">
                                        <TextArea
                                            name="explanation"
                                            value={questionData.explanation}
                                            onChange={(e) => handleChange(e, index)}
                                            required
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            type="danger"
                                            onClick={() => deleteQuestion(questionData.id)}>
                                            Delete
                                        </Button>
                                        <Button type="primary" danger onClick={showDeleteConfirm} style={{ marginBottom: '20px' }}>
                                            Delete Duplicates
                                        </Button>
                                        <Button
                                            type="primary"
                                            onClick={() => updateQuestion(questionData.id)} // Updated to use questionData.id
                                            disabled={!modifiedIndexes[questions.findIndex(q => q.id === questionData.id)]} // Ensure the disabled prop works based on id
                                        >
                                            Save Changes
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Card>
                        ))}
                    </Panel>
                ))}
            </Collapse>
        </div>
    );
};

export default EditQuestion;
