import React, { useEffect } from 'react';
import { Card, Radio } from 'antd';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';

const NewlineText = ({ text }) => {
    const newText = text.split('\n').map((str, index, array) =>
        index === array.length - 1 ? str : <>
            {str}
            <br/>
        </>
    );

    return <>{newText}</>;
};

const Question = ({ question, selectOption, selectedOption }) => {
    // Ensure that 'question' is defined and has an 'id' before using it
    useEffect(() => {
        if (question && question.id) {
            updateQuestionAnalytics(question.id);
        }
    }, [question]);

    const updateQuestionAnalytics = async (questionId) => {
        const db = getFirestore();
        const questionRef = doc(db, "questions", questionId);

        const docSnap = await getDoc(questionRef);
        if (docSnap.exists()) {
            const currentData = docSnap.data();
            const updatedViews = (currentData.views || 0) + 1;

            await updateDoc(questionRef, {
                views: updatedViews,
            });
        }
    };

    if (!question) {
        return <div>Loading...</div>;
    }

    const onChange = e => {
        selectOption(e.target.value.toString());
    };

    return (
        <div className="question-container">
            <Card style={{ maxWidth: '100%', margin: 'auto', padding: '20px' }}>
                <h4>Topic: <NewlineText text={question.topic} /></h4>
                <h3><NewlineText text={question.question} /></h3>
                <Radio.Group onChange={onChange} value={selectedOption ? selectedOption.toString() : null}>
                    {question.options.map((option, index) => (
                        <Radio style={{ display: 'block', margin: '10px 0' }} value={index.toString()} key={index}>
                            {option}
                        </Radio>
                    ))}
                </Radio.Group>
            </Card>
        </div>
    );
};

export default Question;
