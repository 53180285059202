import React, { useState, useEffect } from 'react';
import { Layout, Typography, Breadcrumb } from 'antd';
import { useLocation } from 'react-router-dom';
import '../styles/StudyGuide.css';
import menu from './SideMenu';  
import menuItems from '../data/menuItems.json';

const { Content, Footer, Sider } = Layout;
const { Title } = Typography;

const findTitleByPath = (items, path) => {
    if (!Array.isArray(items)) {
        console.error('items is not iterable', items);
        return null;
    }

    for (const item of items) {
        if (item.path === path) return item.title;
        if (item.children) {
            const foundTitle = findTitleByPath(item.children, path);
            if (foundTitle) return foundTitle;
        }
    }
    return null;
};

const StudyGuide = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const pathSnippets = location.pathname.split('/').filter(i => i);

  const breadcrumbItems = pathSnippets.map((snippet, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    const title = findTitleByPath(menuItems.menuItems, url) || snippet;
    return (
        <Breadcrumb.Item key={url}>
            {title}
        </Breadcrumb.Item>
    );
});

  return (
    <Layout style={{ minHeight: '100vh', marginTop: '60px' }}>
        {!isMobile && (
          <Sider width={300} className="site-layout-background">
            {menu}
          </Sider>
        )}

      <Layout>
      <Content style={{ padding: isMobile ? '16px' : '0 50px' }}>
          <Breadcrumb style={{ margin: '16px 0' }}>
            {breadcrumbItems}
          </Breadcrumb>
          <div className="site-layout-content">
            <Title>{breadcrumbItems[breadcrumbItems.length - 1].props.children}</Title>
            <p>This section introduces the CFA Level 1 exam topics and structure.</p>
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>CFA Mock Exam ©2023</Footer>
      </Layout>
    </Layout>
  );
};

export default StudyGuide;
