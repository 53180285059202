// Navbar.js
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { auth, db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import '../styles/navbar.css';
import { Menu, Dropdown, Avatar, Drawer } from 'antd';
import { UserOutlined, MenuOutlined } from '@ant-design/icons';
import sideMenu from './SideMenu';

const Navbar = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showLogoOnly, setShowLogoOnly] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [open, setOpen] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false); // State to track if the user is an admin

    const location = useLocation();

    useEffect(() => {
        if (user) {
            // Fetch user profile data
            const fetchUserProfile = async () => {
                const userDoc = await getDoc(doc(db, "userProfiles", user.uid));
                if (userDoc.exists()) {
                    setIsAdmin(userDoc.data().isAdmin); // Set isAdmin state based on user profile data
                }
            };

            fetchUserProfile().catch(console.error);
        } else {
            setIsAdmin(false); // If logged out, set isAdmin to false
        }
    }, [user]);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            setUser(currentUser);
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        setShowLogoOnly(location.pathname.includes('/quiz'));
    }, [location.pathname]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const userProfileMenu = (
        <Menu>
            <Menu.Item>
                <Link to="/edit-profile">Edit Profile</Link>
            </Menu.Item>
            <Menu.Item>
                <Link to="/quiz-attempts">Quiz History</Link>
            </Menu.Item>
            {isAdmin && (
                <>
                    <Menu.Item>
                        <Link to="/submit-question">Submit Question</Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="/edit-questions">Edit Question</Link>
                    </Menu.Item>
                </>
            )}
            <Menu.Item>
                <Link to="/settings">Settings</Link>
            </Menu.Item>
            <Menu.Item>
                <span onClick={() => auth.signOut()}>Logout</span>
            </Menu.Item>
        </Menu>
    );

    if (loading) {
        return null;
    }

    return (
        <div className="navbar">
            <div className="left-section">
                {location.pathname.startsWith('/study-guide') && !showLogoOnly && isMobile && (
                    <MenuOutlined
                        className="menu-button"
                        type="primary"
                        onClick={showDrawer}
                        style={{ fontSize: '15px' }}
                    />
                )}
                <Link
                    to="/"
                    className={`logo${location.pathname.startsWith('/study-guide') && isMobile ? ' study-guide-logo' : ''}`}
                    style={{ marginTop: '-6px' }}
                >
                    CFA Mock Exam
                </Link>
            </div>

            {!showLogoOnly && (
                <div className="navbar-content">
                    {
                        user ? (
                            <Dropdown overlay={userProfileMenu} trigger={['click']}>
                                <Avatar icon={<UserOutlined />} />
                            </Dropdown>
                        ) : (
                            <>
                                <Link to="/login" className="login-button">Log In</Link>
                                <Link to="/signup" className="signup-button">Sign Up</Link>
                            </>
                        )
                    }
                </div>
            )}
            {isMobile && location.pathname.startsWith('/study-guide') && (
                <Drawer
                    placement="left"
                    open={open}
                    onClose={onClose}
                    width="75%"
                >
                    {sideMenu}
                </Drawer>
            )}

        </div>
    );

};

export default Navbar;