import React, { useState } from 'react';
import { addDoc, collection, getFirestore } from 'firebase/firestore';
import { auth } from '../firebase';
import { Form, Input, Button, Select, Card, notification } from 'antd';

const { TextArea } = Input;
const { Option } = Select;

const SubmitQuestion = () => {
    const [question, setQuestion] = useState({
        uid: auth.currentUser.uid,
        submittedTime: new Date().toISOString(),
        difficultyLevel: '',
        topic: '',
        source: '',
        cfaLevel: '', // Added CFA Level field
        question: '',
        options: ['', '', ''],
        correctAnswer: '',
        explanation: ''
    });

    const db = getFirestore();

    const handleChange = (e) => {
        setQuestion({ ...question, [e.target.name]: e.target.value });
    };

    const handleOptionChange = (value, index) => {
        const newOptions = [...question.options];
        newOptions[index] = value;
        setQuestion({ ...question, options: newOptions });
    };

    const handleSubmit = async () => {
        try {
            // Update the submittedTime to the current time when the form is submitted
            const updatedQuestion = {
                ...question,
                submittedTime: new Date().toISOString() // Current time
            };
    
            await addDoc(collection(db, 'questions'), updatedQuestion);
            notification.success({
                message: 'Success',
                description: 'Question submitted successfully!'
            });
    
            // Clear all fields except 'Topic', 'Source', and 'CFA Level'
            setQuestion({
                ...question,
                question: '',
                options: ['', '', ''],
                correctAnswer: '',
                explanation: ''
            });
        } catch (e) {
            notification.error({
                message: 'Error',
                description: 'There was an error submitting the question.'
            });
            console.error('Error adding document: ', e);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
            <Card title="Submit Question" style={{ width: 800 }}>
                <Form onFinish={handleSubmit}>
                    <Form.Item label="Topic">
                        <Input
                            name="topic"
                            value={question.topic}
                            onChange={handleChange}
                            required
                        />
                    </Form.Item>
                    <Form.Item label="Source">
                        <Input
                            name="source"
                            value={question.source}
                            onChange={handleChange}
                        />
                    </Form.Item>
                    <Form.Item label="CFA Level">
                        <Select
                            name="cfaLevel"
                            value={question.cfaLevel}
                            onChange={value => setQuestion({ ...question, cfaLevel: value })}
                        >
                            <Option value="CFA Level 1">CFA Level 1</Option>
                            <Option value="CFA Level 2">CFA Level 2</Option>
                            <Option value="CFA Level 3">CFA Level 3</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Question">
                        <TextArea
                            name="question"
                            value={question.question}
                            onChange={handleChange}
                            required
                        />
                    </Form.Item>
                    {question.options.map((option, i) => (
                        <Form.Item label={`Option ${String.fromCharCode(65 + i)}`} key={i}>
                            <Input
                                value={option}
                                onChange={(e) => handleOptionChange(e.target.value, i)}
                                required
                            />
                        </Form.Item>
                    ))}
                    <Form.Item label="Correct Answer">
                        <Select
                            value={question.correctAnswer}
                            onChange={value => setQuestion({ ...question, correctAnswer: value })}
                            required
                        >
                            <Option value="0">Option A</Option>
                            <Option value="1">Option B</Option>
                            <Option value="2">Option C</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Explanation">
                        <TextArea
                            name="explanation"
                            value={question.explanation}
                            onChange={handleChange}
                            required
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit Question
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
};

export default SubmitQuestion;
