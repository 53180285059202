import React, { useEffect, useState, useCallback } from 'react';
import { Typography, Card, Button, Modal, Form, Input } from 'antd';
import { Link } from 'react-router-dom';
import { Timestamp, doc, setDoc, addDoc, collection } from 'firebase/firestore';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, updateProfile } from "firebase/auth";
import { auth, db } from '../firebase';

const { Title } = Typography;

const NewlineText = ({ text }) => {
    const newText = text.split('\n').map((str, index, array) =>
        index === array.length - 1 ? str : <>
            {str}
            <br />
        </>
    );

    return <>{newText}</>;
};


const Results = ({ questions, selectedAnswers, setQuizKey, resetAndFetchQuestions, isQuizComplete, quizStartTime, topic }) => {
    const [isSignupModalVisible, setIsSignupModalVisible] = useState(false);
    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
    const correctAnswers = questions.map(q => q.correctAnswer);
    // eslint-disable-next-line
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [justAuthenticated, setJustAuthenticated] = useState(false); // New state variable
    const [user, setUser] = useState(null); // Track the authenticated user

    const score = selectedAnswers.reduce((acc, answer, index) => (answer !== null && answer === correctAnswers[index].toString()) ? acc + 1 : acc, 0);
    const percentageScore = ((score / questions.length) * 100).toFixed(2);

    const storeQuizAttempt = useCallback(async (quizEndTime) => {
        try {
            const docRef = await addDoc(collection(db, "quizAttempts"), {
                uid: auth.currentUser.uid,
                startTime: quizStartTime,
                endTime: quizEndTime,
                questions: questions,
                answers: selectedAnswers,
                score: percentageScore,
                topic: topic // Include the topic here
            });
            console.log("Quiz attempt stored successfully with ID:", docRef.id);
        } catch (error) {
            console.error("Error storing quiz attempt:", error);
        }
    }, [quizStartTime, questions, selectedAnswers, percentageScore, topic]);

    const toggleBodyScroll = (shouldDisableScroll) => {
        document.body.style.overflow = shouldDisableScroll ? 'hidden' : '';
    };

    useEffect(() => {
        const isAnyModalVisible = isSignupModalVisible || isLoginModalVisible || isModalVisible;
        toggleBodyScroll(isAnyModalVisible);

        return () => {
            toggleBodyScroll(false);
        };
    }, [isSignupModalVisible, isLoginModalVisible, isModalVisible]);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser); // Update the user state on auth state change
            if (currentUser) {
                setIsSignupModalVisible(false);
                setIsLoginModalVisible(false);
            }
        });

        return () => unsubscribe();
    }, []);

    // Modify your useEffect that stores the quiz attempt to consider the user state
    useEffect(() => {
        if (isQuizComplete && !submitted && user) {
            setSubmitted(true);
            storeQuizAttempt(Timestamp.now());
        }
    }, [isQuizComplete, submitted, storeQuizAttempt, user]);

    const restartQuiz = () => {
        setQuizKey(prevKey => prevKey + 1);
        resetAndFetchQuestions();
    };

    const handleSignUp = async (values) => {
        try {
            const { email, password, name } = values;
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
    
            // Update the user's profile with the name
            await updateProfile(user, { displayName: name });
    
            // Add the new user's data to the userProfiles collection
            const userRef = doc(db, "userProfiles", user.uid);
            await setDoc(userRef, {
                name: name,
                email: user.email,
                uid: user.uid,
                dateCreated: Timestamp.now() // Add the current timestamp
            }, { merge: true });
    
            setIsSignupModalVisible(false);
            setJustAuthenticated(true);
        } catch (error) {
            console.error('Error during sign up:', error);
            // Handle the error, e.g., show a notification
        }
    };
    

    const handleLogin = async (values) => {
        try {
            await signInWithEmailAndPassword(auth, values.email, values.password);
            setIsLoginModalVisible(false);
            setJustAuthenticated(true); // Set to true after successful login
        } catch (error) {
            console.error('Error during log in:', error);
        }
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user || justAuthenticated) {
                setIsModalVisible(false);
            } else {
                setIsModalVisible(true);
            }
        });

        return () => unsubscribe();
    }, [justAuthenticated]);

    return (
        <div className="results-container" style={{ textAlign: 'center' }}>
            {/* Only show the main modal if the user is not authenticated */}
            {!user && (
                <Modal
                    title="Sign Up Required"
                    visible={!isSignupModalVisible && !isLoginModalVisible}
                    footer={null}
                    closable={false}
                    maskClosable={false}
                >
                    <p>You need to sign up or log in to view your results.</p>
                    <Button type="primary" onClick={() => setIsSignupModalVisible(true)}>Sign Up</Button>
                    <Button type="default" onClick={() => setIsLoginModalVisible(true)}>Log In</Button>
                </Modal>
            )}

            <Modal
                title="Sign Up"
                visible={isSignupModalVisible}
                footer={null}
                onCancel={() => setIsSignupModalVisible(false)}
            >
                <Form onFinish={handleSignUp}>
                    <Form.Item name="name" rules={[{ required: true, message: 'Please input your name!' }]}>
                        <Input placeholder="Name" />
                    </Form.Item>
                    <Form.Item name="email" rules={[{ required: true, message: 'Please input your email!' }]}>
                        <Input placeholder="Email" />
                    </Form.Item>
                    <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
                        <Input.Password placeholder="Password" />
                    </Form.Item>
                    <Button type="primary" htmlType="submit">Sign Up</Button>
                </Form>
            </Modal>

            <Modal
                title="Log In"
                visible={isLoginModalVisible}
                footer={null}
                onCancel={() => setIsLoginModalVisible(false)}
            >
                <Form onFinish={handleLogin}>
                    <Form.Item name="email" rules={[{ required: true, message: 'Please input your email!' }]}>
                        <Input placeholder="Email" />
                    </Form.Item>
                    <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
                        <Input.Password placeholder="Password" />
                    </Form.Item>
                    <Button type="primary" htmlType="submit">Log In</Button>
                </Form>
            </Modal>
            <Title level={2}>Exam Results</Title>
            <Title level={4}>Your Score:</Title>
            <Title level={3}>{percentageScore}%</Title>
            <Title level={5}>{score} out of {questions.length} questions</Title>
            <div className="questions-results">
                {questions.map((question, index) => (
                    <Card key={index} style={{ margin: '20px auto', maxWidth: '800px', textAlign: 'left' }}>
                        <h3>Q{index + 1}: <NewlineText text={question.question} /></h3>

                        {question.options.map((option, optionIndex) => {
                            const isCorrect = optionIndex.toString() === question.correctAnswer;
                            const isSelected = selectedAnswers[index] === optionIndex.toString();
                            return (
                                <p key={optionIndex} style={{
                                    color: isCorrect ? 'green' : (isSelected ? 'red' : 'black'),
                                    fontWeight: isCorrect || isSelected ? 'bold' : 'normal'
                                }}>
                                    {String.fromCharCode(65 + optionIndex)}: {option}
                                </p>
                            );
                        })}

                        {question.explanation && (
                            <p style={{ fontStyle: 'italic', textAlign: 'left' }}>
                                Explanation: <NewlineText text={question.explanation} />
                            </p>
                        )}
                    </Card>
                ))}
            </div>
            <Button type="default" style={{ marginTop: '20px' }} onClick={restartQuiz}>
                Try Again
            </Button>
            <Link to="/">
                <Button type="primary" style={{ marginTop: '20px' }}>
                    Back to Home
                </Button>
            </Link>
        </div>
    );
};

export default Results;
