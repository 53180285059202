import React, { useState, useEffect } from 'react';
import { Button, Input, Card, Row, Col, Form, Typography, notification, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { db, auth } from '../firebase';
import { collection, addDoc, getDocs, query, doc, deleteDoc } from 'firebase/firestore';

const { Text } = Typography;
const { TextArea } = Input;

const Glossary = () => {
    const [terms, setTerms] = useState([]);
    const [search, setSearch] = useState('');
    const [focusedTermId, setFocusedTermId] = useState(null);

    useEffect(() => {
        const fetchTerms = async () => {
            try {
                const q = query(collection(db, 'terms'));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const fetchedTerms = querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data(),
                    }));
                    setTerms(fetchedTerms);
                } else {
                    console.error('No terms found');
                }
            } catch (error) {
                console.error('Error fetching terms:', error);
            }
        };

        fetchTerms();
    }, []);

    const addTerm = async ({ term, explanation, topic }) => {  // Added topic to the destructure
        try {
            const termCol = collection(db, 'terms');
            const docRef = await addDoc(termCol, {
                term,
                explanation,
                topic,
                addedBy: auth.currentUser.uid,
                addedAt: new Date().toISOString()
            });

            setTerms(prevTerms => [...prevTerms, {
                id: docRef.id,
                term,
                explanation,
                topic 
            }]);

            notification.success({
                message: 'Success',
                description: 'Term added successfully!',
            });
        } catch (error) {
            // ... (same as before)
        }
    };

    const deleteTerm = async id => {
        try {
            if (id) {  // Check if id is valid
                await deleteDoc(doc(db, 'terms', id));
                setTerms(prevTerms => prevTerms.filter(term => term.id !== id));
                notification.success({
                    message: 'Deleted',
                    description: 'Term deleted successfully!',
                });
            }
        } catch (error) {
            console.error('Error deleting term:', error);
        }
    };

    const filteredTerms = terms.filter(term =>
        term.term.toLowerCase().includes(search.toLowerCase()) ||
        term.explanation.toLowerCase().includes(search.toLowerCase()) ||
        (term.topic && term.topic.toLowerCase().includes(search.toLowerCase()))
    );


    return (
        <div style={{ padding: '30px' }}>
            <h3>Glossary of CFA Level 1 Terms</h3>

            <Form
                onFinish={addTerm}
                style={{ marginBottom: '20px' }}
            >
                <Form.Item
                    name="term"
                    rules={[{ required: true, message: 'Please input a term!' }]}
                >
                    <Input placeholder="Term" />
                </Form.Item>
                <Form.Item
                    name="explanation"
                    rules={[{ required: true, message: 'Please input an explanation!' }]}
                >
                    <TextArea placeholder="Explanation" />
                </Form.Item>
                <Form.Item
                    name="topic"
                    rules={[{ required: true, message: 'Please input a topic!' }]}
                >
                    <Input placeholder="Topic" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Add Term
                    </Button>
                </Form.Item>
            </Form>

            <Input
                placeholder="Search"
                value={search}
                onChange={e => setSearch(e.target.value)}
                style={{ marginBottom: '20px' }}
            />

            <Row gutter={16}>
                {filteredTerms.map((item, index) => (
                    <Col key={index} xs={24} sm={12} md={8} lg={6} xl={4}>
                        <Card
                            hoverable
                            style={{ marginBottom: '20px' }}
                            onFocus={() => setFocusedTermId(item.id)} // Set focused term ID when card is focused
                            onBlur={() => setFocusedTermId(null)} // Clear focused term ID when focus is lost
                            tabIndex={0} // Make the card focusable
                            actions={focusedTermId === item.id ? [ // Conditionally render the trash can based on focus
                                <Popconfirm title="Are you sure to delete this term?"
                                    onConfirm={() => deleteTerm(item.id)}
                                    okText="Yes"
                                    cancelText="No">
                                    <DeleteOutlined />
                                </Popconfirm>
                            ] : []}
                        >
                            <Text strong>{item.term}</Text>
                            <br />
                            <p style={{ whiteSpace: 'pre-line' }}>{item.explanation}</p>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default Glossary;