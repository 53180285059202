import React, { useState } from 'react';
import { auth, db } from '../firebase';
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { doc, setDoc } from 'firebase/firestore';
import { Card, Input, Button, notification } from 'antd';
import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons'; // Import for success icon
import { useNavigate } from 'react-router-dom';

const SignUp = () => {
    const [formData, setFormData] = useState({ email: '', password: '' });
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
            const user = userCredential.user;

            await updateProfile(user, {
                displayName: formData.name
            });

            await setDoc(doc(db, "userProfiles", user.uid), {
                name: formData.name,
                email: user.email,
                dateCreated: new Date()
            });

            // Display success notification
            notification.open({
                message: 'Sign Up Successful',
                description: 'Welcome to CFA Mock Exam, your account has been created successfully!',
                icon: <CheckCircleOutlined style={{ color: 'green' }} />,
            });

            navigate('/');
        } catch (error) {
            console.error('Error during sign up', error);
            notification.open({
                message: 'Sign Up Error',
                description: error.message,
                icon: <WarningOutlined style={{ color: 'red' }} />,
            });
        }
    }
    return (
        <div className="signup-container">
            <Card title="Sign Up" style={{ width: 300, margin: 'auto' }}>
                <form onSubmit={handleSubmit}>
                    <label>
                        Name
                        <Input 
                            type="text" 
                            name="name" 
                            value={formData.name} 
                            onChange={handleChange} 
                            required 
                        />
                    </label>
                    <br/>
                    <label>
                        Email
                        <Input 
                            type="email" 
                            name="email" 
                            value={formData.email} 
                            onChange={handleChange} 
                            required 
                        />
                    </label>
                    <br/>
                    <label>
                        Password
                        <Input 
                            type="password" 
                            name="password" 
                            value={formData.password} 
                            onChange={handleChange} 
                            required 
                        />
                    </label>
                    <br/>
                    <Button type="primary" htmlType="submit" style={{ marginTop: '20px' }}>Sign Up</Button>
                </form>
            </Card>
        </div>
    );
}

export default SignUp;